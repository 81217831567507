@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.destxt {
  font-size: 14px;
  line-height: 20px;
  font-weight: 200;
  word-break: break-word;

  @media (max-width: 575px) {
    padding: 0 0px 0 0;
  }
}
.showdisbth {
  color: #a91722;
  padding: 10px 0;
  cursor: pointer;
  font-size: 12px;
}
.readm{
    cursor: pointer;
    &:hover{
        text-decoration: none !important;
    }
}
