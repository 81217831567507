@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';
.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  background: #f2f1ef;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  max-width: 1440px;
  /* max-height: 1545px; */
  margin: 0 auto;
  padding: 0 100px;
  display: flex;
  position: relative;
  background: #f2f1ef;
  justify-content: space-between;

  @media (max-width: 1023px) {
    padding: 0 24px;
  }
  /* @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  } */
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(60% - 10px);
    flex-basis: calc(60% - 10px);
    flex-shrink: 0;
    flex-grow: 1;
  }
  @media (max-width: 1023px) {
    margin-bottom: 0 !important;
  }
}
.noUser {
  color: #000;
  margin-top: 50px;
}
.noUser div {
}
.noUser p span a {
  color: #c93b54 !important;
}
.bookingPanel {
  /* @media (max-width: 1023px) {
    display: none;
  } */
  @media (--viewportLarge) {
    display: block;
    margin-top: 50px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);
    padding: 0;
    flex-basis: calc(40% - 10px);
    max-width: calc(40% - 10px);
    flex-shrink: 0;
    /* @media (min-width: 1200px) {
      flex-basis: 40%;
      max-width: 40%;
    } */
  }
}
.cpycnt {
  flex-direction: column;
  gap: 30px;
  @media (max-width: 1023px) {
    & > div {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}
.footerTop {
  @media (max-width: 1023px) {
    margin-top: 0 !important;
  }
}
.bckdiv {
  max-width: 1440px;
  margin: 10px auto 0;
  padding-left: 24px;
}
.bckbtn {
  border: 0px;
  font-size: 15px;
  cursor: pointer;
  /* max-width: 195px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.totop span {
  padding: 4px;
  border-radius: 50%;
  border: 2px solid #cecece;
  animation: jump 0.4s linear alternate infinite;
  cursor: pointer;
}
.totop {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 10px;
}
.totop svg {
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.bckbtn span {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -72px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  /* height: calc(100vh - 193px);
  width: 100%; */

  /* Static map: dimensions are 640px */
  /* max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer; */

  /* @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  } */
}
.mapbox {
  height: 100%;
  width: 100%;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.listingslider {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  max-height: 450px;
  border-radius: 10px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 575px) {
    max-height: 350px;
  }
}
.listingslider .tssliderrow {
  width: 100%;
}
.tssliderrow .tsslidercolimg img {
  min-height: 100%;
  object-fit: cover;
  width: 100%;
  max-height: 450px;
  border-radius: 10px;
}

.cntctpr {
  margin-top: 30px !important;
}
.createListingButton {
  border-radius: 6px;
  margin: 0 0 20px;
  border: 0px;
  color: #fff;
  font-size: 15px;
  padding: 5px 20px;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  display: inline-block;
  cursor: pointer;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 72%, rgba(201, 59, 84, 1) 27%);
  }
}
.createListingButton:hover,
.shrBtn:hover {
  text-decoration: none;
  background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
}
.createListingButton i {
  font-size: 18px;
  margin: 0 10px 0 0;
}
.sharesec {
  margin: 20px 0;
  display: flex;
  cursor: pointer;
}
.sharesec div {
  color: #c93b54;
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.sharesec div span {
  width: 30px;
  height: 30px;
  background: #f2f1ef;
  display: inline-flex;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}
.sharesec div svg {
  color: var(--matterColorDark);
  font-size: 18px;
}
.shrBtn {
  position: absolute;
  display: flex;
  @media (max-width: 767px) {
    border: 1px solid #fff;
  }
}

.secwrow {
  width: 100%;
  padding: 24px;
  border-radius: 6px;
  margin: 0px 0px 15px;
  background: #fff;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
}
.secwrow h2 {
  color: #232323;
  font-size: 24px;
  margin: 0 0 15px;
  text-decoration: underline;
}
.reviewsec {
  color: #232323;
  font-size: 16px;
  margin-bottom: 30px;
  display: block;
}
.reviewsec i {
  color: #ffbb52;
  margin: 0 8px 0 0;
  font-size: 18px;
}
.reviewsec span {
  font-weight: 600;
}
.autspann {
  color: #c93b54;
  font-weight: 600;
  margin: 0 0 0 15px;
  font-size: 20px;
  @media (max-width: 575px) {
    font-size: 14px;
  }
}
.listingul {
  margin: 0;
  padding: 10px 0 0 50px;
  @media (max-width: 575px) {
    padding: 10px 0;
  }
}
.ptvul li:nth-child(2) {
  display: block;
}
.ptvul li:nth-child(2)::before {
  top: 14px;
  @media (max-width: 767px) {
    top: 9px;
  }
}
.listingul li {
  position: relative;
  font-size: 20px;
  color: #000;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  padding: 0 0 0 25px;
  @media (max-width: 575px) {
    align-items: flex-start;
    margin-bottom: 10px;
    font-size: 16px;
  }
}
.listingul li span {
  font-weight: 800;
  margin: 0 10px 0 0;
}
.listingul li::before {
  background: #ad247c;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  position: absolute;
  content: '';
  left: 4px;
  @media (max-width: 575px) {
    top: 8px;
  }
}
.toul {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 10px 15px;
  @media (max-width: 575px) {
    margin-top: 10px;
  }
}
.toul li {
  color: #5d5d5d;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.toul li svg {
  width: 25px;
  height: 25px;
  margin: 0 0 0 5px;
  @media (max-width: 575px) {
    margin: 0 0 0 10px;
    width: 20px;
    height: 20px;
  }
}
.title {
  font-family: 'Stardos Stencil', cursive;
  font-size: 45px;
  color: #232323;
  margin: 0 0 15px;
  line-height: 60px;
  font-weight: 500;
  @media (max-width: 575px) {
    font-size: 30px;
    line-height: 40px;
  }
}
.abtprvm {
  padding-top: 0 !important;
}
.section {
  padding: 15px 0;
}
.listSection {
  margin-bottom: 40px;
}
.abtact {
  margin-bottom: 40px;
}
.readmbtn {
  /* background: #c93b54; */
  color: #c93b54;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 600;
  float: right;
  margin: 0px 0 0;
  margin-left: auto;
  cursor: pointer;
}
.posstrcont {
  width: auto;
  margin-left: 10px;
}
.lsttxt {
  color: #232323;
  font-size: 18px;
  margin-top: 0px !important;
  text-decoration: none !important;
  line-height: 30px !important;
  @media (max-width: 575px) {
    font-size: 16px;
    line-height: 24px !important;
  }
}
.listingmap {
  padding: 15px 100px;
  /* background: #fff; */
  height: 500px;
  width: 100%;
  margin: 40px auto;
  max-width: 1440px;
  @media (max-width: 1023px) {
    margin: 0 auto;
    padding-top: 0 !important;
  }
  @media (max-width: 767px) {
    padding: 24px;
  }
}

.listingrevaut {
  display: flex;
  align-items: flex-start;
}
.listingrevaut a {
  /* width: 80px;
  height: 80px;
  border-radius: 50px; */
  margin: 0 15px 0 0;
}
.listingrevaut p {
  color: #000;
  margin: 0;
  line-height: 25px;
  text-decoration: underline;
}
.headqin {
  text-decoration: none !important;
  margin-bottom: 20px !important;
}
.autpvdname {
  color: #c93b54 !important;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px !important;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.mc0 {
  margin: 30px 0 30px !important;
  background: #fff;
  padding: 24px;
  box-shadow: 1px 1px 8px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  @media (max-width: 1023px) {
    margin-top: 10px !important;
    box-shadow: 1px 1px 8px rgb(0 0 0 / 20%) !important;
  }
  @media (max-width: 575px) {
    margin: 0 !important;
  }
}
.reviewsecpanel {
  width: 100%;
  padding: 24px;
  border-radius: 6px;
  margin: 30px 0 30px 60px;
  background: #fff;
  box-shadow: 1px 1px 8px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  & .readmbtn {
    margin-top: 0 !important;
  }
  @media (max-width: 1023px) {
    margin: 0;
    box-shadow: 1px 1px 8px rgb(0 0 0 / 20%) !important;
  }
}
.revRating {
  margin-bottom: 20px;
  & > p {
    margin: 0;
  }
  & > span {
    display: flex;
    align-items: center;
    font-weight: 700;
    & svg {
      fill: #ffb300;
    }
    & svg:first-child {
      margin-left: 10px;
    }
  }
}
.revRating:last-child {
  margin-bottom: 0 !important;
}
.reviewsecpanel h3 {
  display: flex;
  align-items: center;
  margin-top: 0;
  font-size: 25px;
  font-weight: 600;
  color: #232323;
  position: relative;
  padding: 0 15px 3px;
  width: max-content;
  line-height: 36px;
  margin-bottom: 20px;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 4px;
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
    left: 0;
  }
}
.reviewsecpanel .createListingButton {
  margin: auto 0 0 auto;
}
.reviewsecpanel p {
  line-height: 24px;
}
.reviewsecpanel p span {
  color: #000;
  font-weight: 700;
  margin: 0 15px 0 0;
}
.reviewsecpanel p i {
  margin: 0 5px;
  font-size: 20px;
}
.reviewsecpanel textarea {
  border: 1px solid #d1d0d0;
  min-height: 110px;
  border-radius: 8px;
  margin: 0 0 15px 0;
  line-height: 20px;
  padding: 15px;
  box-sizing: border-box;
}
.reviewsecpanel button {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  padding: 2px 28px;
  display: flex;
  margin: 0 0 0 auto;
  font-size: 14px;
  color: #c4385a;
  font-weight: 500;
  cursor: pointer;
}
.providerListings {
  position: relative;
  padding: 50px 0;
  background: #fff;
}
.providerListings .title {
  text-align: center;
}
.providerListings .section {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: 1128px;
  margin: 0 auto;
  width: 100%;
  padding: 50px 0 10px;
  @media (max-width: 575px) {
    width: 90%;
  }
}
.providerListings .profilecol {
  flex-basis: 30%;
  max-width: 30%;
  padding: 0;
  margin: 0 15px 30px;
  border: 1px solid #d1d0d0;
  border-radius: 16px 16px 10px 10px;
  background: #fff;
  box-shadow: 1px 1px 8px 3px rgba(0, 0, 0, 0.2);
  @media (max-width: 991px) {
    flex-basis: 46%;
    max-width: 46%;
  }
  @media (max-width: 767px) {
    flex-basis: 44%;
    max-width: 44%;
  }
  @media (max-width: 575px) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.providerListings .upArrow {
  position: fixed;
  bottom: 90px;
  right: 90px;
  z-index: 999;
}
.providerListings .upArrow .upArrowBtn {
  height: 30px;
  width: 30px;
  color: var(--matterColorAnti);
}
.riverowlist {
  padding-bottom: 100px;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 20px;
  }
}
.fscsection {
  background: #f2f1ef;
  padding: 50px 0 135px;
}
.innersecrowbtm {
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
  }
}
.innersecrowbtmcolp {
  padding-right: 15px;
}
.innersecrowbtmcolg {
  padding: 15px;
  flex-basis: 30%;
  max-width: 30%;
  border-left: 1px solid #f2f1ef;
  @media (max-width: 991px) {
    flex-basis: 100%;
    max-width: 100%;
    border-left: none;
  }
}
.innersecrowbtmcolg p {
  color: #232323;
  font-size: 22px;
  text-align: center;
  margin: 0 0 30px;
  line-height: 40px;
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 30px;
  }
}
.innersecrowbtmcolg .createListingButton {
  display: block;
  max-width: 75%;
  text-align: center;
  margin: 0 auto 15px;
  @media (max-width: 575px) {
    max-width: 100%;
  }
}
.pagination {
  justify-content: space-evenly;
  & a {
    color: #c93b54;
  }
  & .next {
    color: #c93b54;
  }
}
.shrcntnt {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  background-color: #f2f1ef;
  padding: 30px 50px 0px;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 15px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
}
.shrsec {
  margin: 40px auto 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 20px 0;
  box-shadow: 1px 1px 8px rgb(0 0 0 / 20%);
  min-width: 520px;
  @media (max-width: 991px) {
    flex-basis: 100%;
    max-width: 100%;
    margin: 0 0 20px;
  }
  @media (max-width: 575px) {
    min-width: unset;
  }

  & > button {
    margin: 10px auto;
    border-radius: 6px;
    border: 0px;
    color: #fff;
    font-size: 18px;
    padding: 2px 45px;
    background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
    display: inline-block;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
    }
  }
  & > span:first-child {
    font-size: 25px;
    line-height: 36px;
    margin: 10px 0 0;
    font-weight: 600;
  }
  & > :nth-child(2) {
    font-size: 16px;
    letter-spacing: 0.6px;
    margin: 10px 0;
  }
  /* & :nth-child(3) {
    border: 2px solid #cecece;
    width: 85%;
    margin: 10px auto 10px;
    border-radius: 11px;
    font-size: 15px;
    line-height: 19px;
    padding: 7px 0;
    color: #c93b54;
  } */
}
.offplt {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 1px 1px 8px rgb(0 0 0 / 20%);
  padding: 20px;
  justify-content: space-evenly;
  top: 80px;
  overflow-y: auto;
  max-height: 560px;
  position: sticky;
}
.wainf {
  height: auto !important;
}
.prcavasec {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  margin: 70px auto 20px;
  text-align: center;
  background-color: #fff;
  height: 250px;
  border-radius: 10px;
  box-shadow: 1px 1px 8px rgb(0 0 0 / 20%);
  & > div {
    margin: 30px auto 20px 85px;
    text-align: left;
    @media (max-width: 575px) {
      width: 280px;
      margin: 0 auto;
    }
  }
  & > :first-child {
    font-size: 25px;
    line-height: 36px;
    margin: 10px auto 0;
    font-weight: 600;
    padding-bottom: 3px;
    width: 280px;
    position: relative;
    @media (max-width: 575px) {
      margin-bottom: 20px;
      display: block;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 4px;
      background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
      left: 0;
    }
  }
}
.additionalPricingInfo {
  text-align: left;
  line-height: 21px;
  /* margin-top: 33px; */
  font-weight: 600;
  @media (max-width: 575px) {
    width: 280px;
    margin: 10px auto 0;
  }
}
.secdiv {
  font-size: 18px;
  font-weight: 600;
  & span {
    color: #c93b54;
    font-size: 18px;
    font-weight: 700;
  }
}
.impdetcls {
  height: 300px !important;
}
.providerOffer {
  margin: 20px auto;
  display: flex;
  padding: 20px 0;
  background-color: #fff;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 1px 1px 8px rgb(0 0 0 / 20%);
  text-align: center;
  @media (max-width: 991px) {
    padding: 20px;
  }
  & > :first-child {
    font-size: 25px;
    line-height: 36px;
    margin: 10px auto 0;
    font-weight: 600;
    position: relative;
    padding: 0 15px 3px;
    color: #232323;
    @media (max-width: 575px) {
      display: block;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 4px;
      background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
      left: 0;
    }
  }
}
.reviewbtm {
  margin-top: 30px;
  margin-bottom: 20px;
}
.ctcnt {
  display: flex;
  margin: 20px auto 10px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  & div {
    /* mar */
    margin: 10px 15px;
    /* flex-basis: 35%; */
    min-width: 125px;
    flex-shrink: 0;
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
    border-radius: 5px;
    border: 2px solid transparent;
    color: #c93b54;
  }
  & label {
    background: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
  }
}
.spin svg {
  stroke: #fff;
}
.impdet {
  margin: 55px auto 20px;
  display: flex;
  flex-direction: column;
  /* height: 250px; */
  height: auto;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 8px rgb(0 0 0 / 20%);
  @media (max-width: 991px) {
    flex-basis: 100%;
    max-width: 100%;
    margin: 0;
    margin-top: 55px !important;
    /* height: auto; */
  }
  @media (max-width: 575px) {
    padding: 10px;
  }
  & > :first-child {
    font-size: 25px;
    line-height: 36px;
    margin: 10px auto 0;
    font-weight: 600;
    position: relative;
    color: #232323;
    /* border-bottom: 4px solid #c93b54; */
    padding: 0 15px 3px;
    @media (max-width: 575px) {
      margin-bottom: 20px;
      display: block;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 4px;
      background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
      left: 0;
    }
  }
  & div {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin-top: 40px;
    margin-bottom: 10px;
    @media (max-width: 575px) {
      margin-top: 20px;
    }
  }
  & div > div {
    display: flex;
    flex-direction: row;
    margin: 0;
    text-align: left !important;
    @media (max-width: 575px) {
      margin: 5px 10px;
      display: block;
    }
    & > span:first-child {
      font-size: 20px;
      font-weight: 700;
      color: #c93b54;
      min-width: 200px;
      @media (max-width: 575px) {
        font-size: 17px;
        min-width: unset;
      }
    }
    & span:nth-child(2) {
      font-size: 17px;
      margin: 0 0 0 10px;
      color: #000;
    }
  }
}
.pop {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.titlesec {
  & div {
    text-align: center;
    & > span:first-child {
      padding-right: 20px;
      border-right: 1px solid #959595;
      letter-spacing: 1px;
      font-size: 30px;
      font-weight: 700;
      margin: 0 5px 0 0;
      @media (max-width: 575px) {
        padding-right: 0;
        border-right: 0;

        font-size: 24px;

        margin: 0 0 5px;
      }
    }
    & > :nth-child(2) {
      padding-left: 20px;
      font-size: 22px;
      color: #c93b54;
      @media (max-width: 575px) {
        padding-left: 0;
        font-size: 16px;
        display: block;
      }
      & > :first-child {
        padding-right: 6px;
      }
      & > :nth-child(2) {
        font-size: 22px;
        font-weight: 700;
        padding-left: 6px;
        @media (max-width: 575px) {
          font-size: 16px;
        }
      }
    }
  }
  & > span {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    display: inherit;
    /* letter-spacing: 1px; */
  }
}
.gallerydiv {
  margin: 20px auto 0;
  max-width: 1440px;
  padding: 0 100px;
  @media (max-width: 767px) {
    margin: 20px 0px;
    padding: 0 24px;
  }
}
/* .imggal {
  border-radius: 15px;
}
.img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
} */
.avtrdv {
  margin-right: 30px;
  @media (max-width: 575px) {
    margin-right: 10px;
  }
  & > div {
    width: 130px;
    height: 130px;
    @media (max-width: 575px) {
      width: 80px;
      height: 80px;
    }
  }
}
.avtrotr {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px 0 0;
  @media (max-width: 575px) {
    padding-top: 0;
  }
}
.wrapperClassName {
  & :global(.image-gallery-slide-wrapper) {
    max-width: 70%;
    @media (max-width: 767px) {
      max-width: 100%;
    }
    /* & button{
      display: none;
    } */
  }
  & :global(.image-gallery-thumbnails-wrapper) {
    /* width: 300px !important; */
    flex-grow: 1;
    & button {
      width: 100% !important;
      height: 100% !important;
      border-radius: 10px;
      & img {
        border-radius: 10px;
        max-height: 280px;
        object-fit: cover;
        background: #000;
        @media (max-width: 767px) {
          /* height: unset; */
          max-height: 120px;
        }
      }
      @media (max-width: 767px) {
        width: 30% !important;
        display: none;
      }
    }
  }
  & :global(.image-gallery-content) {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  & :global(.image-gallery-slides) {
    border-radius: 10px;
  }
}
.thumbnail {
}
.mainimg {
  height: 400px;
  background-color: #000;
  @media (max-width: 575px) {
    height: 340px;
  }
}
.mainimg img {
  height: 100%;
  width: 100% !important;
  object-fit: contain !important;
}
.orgimgdiv {
  position: relative;

  & img {
    border-radius: 10px;
    width: 100%;
  }
}
.opentmngs {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  box-shadow: 1px 1px 8px rgb(0 0 0 / 20%);
  padding: 20px;
  margin-top: 20px;
  & label {
    font-size: 25px;
    line-height: 36px;
    font-weight: 600;
    text-align: center;
    padding: 0 15px 6px;
    margin: 10px auto 20px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 4px;
      background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
      left: 0;
    }
  }
}
.opnspn {
  & span {
    color: #c93b54;
    font-size: 16px;
    font-weight: 700;
  }
}
.favicon {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 5px;
  background: #fff;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 9;
}
.cstmctrl {
  width: 100%;
  height: 0px;
  background: transparent;
}

.shrBtn {
  right: 35px;
  width: 105px;
  z-index: 9;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 0px;
  color: #fff;
  font-size: 15px;
  /* padding: 5px 20px; */
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  cursor: pointer;
  bottom: -17px;

  &:hover {
    background: linear-gradient(270deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  }
}
.sharebtnbanner {
  position: absolute;
  z-index: 9;
  /* margin: 0 15px; */
  cursor: pointer;
  @media (max-width: 575px) {
    /* margin: 0 10px; */
    font-size: 14px;
  }
}
.fav {
  fill: red;
}
.favouriteLoading {
  animation: favouriteHeartAnimation 0.2s linear alternate;
  cursor: pointer;
}
@keyframes favouriteHeartAnimation {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1.5);
  }
}
@keyframes jump {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 30%, 0);
  }
  /* 0%   {}
  40%  {transform: translate3d(0,30%,0) ;}
  100% {} */
}
.lfwve svg {
  & path {
    background: #002435;
  }
}
.ftrwrp > div:nth-child(2) > div {
  @media (max-width: 575px) {
    margin-top: 0;
  }
  & > p {
    @media (max-width: 575px) {
      margin-top: 0;
    }
  }
}
/* .profmore {
  font-size: 16px !important;
  @media (max-width: 575px) {
    font-size: 15px !important;
  }
} */
.spdv {
  z-index: 1;
  position: relative;
}
.spcspn {
  position: absolute;
  top: 4px;
  left: 4px;
  color: #fff;
  padding: 1px 15px;
  font-size: 14px;
  line-height: 25px;
  &:after {
    content: '';
    background-color: #002435;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.7;
    border-radius: 5px;
    @media (max-width: 575px) {
      z-index: -1;
    }
  }
}
.dispn {
  display: none;
}
.shrBtnsDv {
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 20px;
  margin-top: 20px;
  max-width: 500px;
  justify-content: center;
}
.shrToBtns {
  /* flex-basis: 30%; */
  padding: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 18px;
  font-weight: 700;
  width: 160px;
  white-space: nowrap;
  & svg {
    width: 50px;
    height: 50px;
    fill: #002435;
  }
}
.ratestar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 15px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  color: #7b7b7b;
  & svg {
    margin-right: 4px;
    fill: #ffb300;
  }
}
.rtng {
  line-height: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
